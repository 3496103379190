body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5ebe0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-updater-node {
  height: 50px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
}

.text-updater-node label {
  display: block;
  color: #777;
  font-size: 12px;
}

.text-node-main-container {
  padding: 10px;
  display: flex;
  align-items: center;
}

.text-response-main-container {
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #5db8e7;
}

.custom-handle {
  background-color: #D563B9;
  border: 2px solid #D563B9;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.custom-handle:hover {
  background-color: #FF8833;
  border: 2px solid #FF8833;
}

.canvas-container {
  height: calc(100vh - 55px);
  display: flex;
  flex-direction: row;
  width: 100dvw;
}

.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

.text-updater-icon {
  margin: 15px;
}

#text {
  position: absolute;
  translate: 40rem 1.5rem;
  color: #fcfcfc;
  scale: 50%;
}

.toolbox-panel {
  padding-left: 30px;
}

.convoInfo-panel {
  right: 10px;
}

.suggestedAction-panel {
  right: 0rem;
}

.exitNode-panel {
  right: 0rem;
}

#header {
  position: relative;
  height: 55px;
  background-color:#FFFFFF;
  z-index: 1001;
  margin-top: 0px;
  display: flex;
  align-items: center;
  box-shadow: 0 8px 6px -6px gray;
}

#logo img {
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  height: 28px;
}

.FeedbackContainer {
  background-color: "#F36A50";
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 12px;
  color: white;
  background: #f36a50;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: max-content;
  position: absolute;
  top: calc(100% + 1px); 
  left: -1px;
}

.errorContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  padding-left: 6px;
  padding-right: 6px;
  color: red;
  border-radius: 4px;
  width: max-content;
  position: absolute;
  top: -34px;
  left: 396px;
  transition: all 0.3s ease-in-out;
}

.errorContainerExitNode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  padding-left: 6px;
  padding-right: 6px;
  color: red;
  border-radius: 4px;
  width: max-content;
  position: absolute;
  top: -34px;
  left: 252px;
  transition: all 0.3s ease-in-out;
}

.errorContainer:hover {
  cursor: pointer;
}

.errorContainerExitNode:hover {
  cursor: pointer;
}

.errorIcon {
  font-size: 30px;
}

.errorMessage {
  display: none;
  position: absolute;
  background-color: white;
  color: black;
  min-width: 160px;
  min-width: 160px;
  padding: 4px 4px;
  z-index: 1;
  font-size: 13px;
  border-radius: 2px;
  left: 10px;
  top: -12px;
  border: 1.5px solid rgb(108, 108, 108)
}

.errorContainer:hover .errorMessage {
  display: block;
}

.errorContainerExitNode:hover .errorMessage {
  display: block;
}

.errorContainer:hover .errorIcon {
  color: rgba(255, 255, 255, 0);
}

.errorContainerExitNode:hover .errorIcon {
  color: rgba(255, 255, 255, 0);
}

.NotEditable {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.2;
  color: white;
  z-index: 1;
}

.display-on-hover {
  position:absolute;
  border: none;
  color: red;
  width: 40px;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px;
  right: -25px;
  top: -25px;
}
.cancelApproval-custom-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 10000;
  overflow-y: auto;
}

.cancelApproval-custom-modal-content {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 680px;
  height: fit-content;
  max-height: 80vh;
}

.cancelApproval-custom-modal-header {
  display: block;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  text-align: center;
  font-size: 32px;
}


.cancelApproval-close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #777;
}

.cancelApproval-close-button:hover {
  color: #333;
}

.cancelApproval-custom-modal-body {
  padding: 20px;
  font-size: 16px;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cancelApproval-btnDiv {
  text-align: right;
}


.cancelApproval-actionBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-top: 16px;
  align-items: center;
}

.cancelApproval-button {
  width: 257px;
  height: 40px;
  border-radius: 5px;
}

.cancelApproval-button.return {
  margin-right: 50px;
  background-color: #D52B1E;
  border: 2px solid #D52B1E;
  color: white;
}

.cancelApproval-button.return:hover {
  color: #D52B1E;
  background-color: white;
  transition: 0.15s ease;
}

.cancelApproval-button.add {
  margin-left: 50px;
  background-color: #00A1DE;
  border: 2px solid #00A1DE;
  color: white;
}

.cancelApproval-button.return:hover {
  background-color: white;
  color: #00A1DE;
  transition: 0.15s ease;
}

@media only screen and (max-width: 1200px) {
  .cancelApproval-custom-modal-content{  
    transform: scale(0.9);
  }
}

@media only screen and (max-width: 1000px) {
  .cancelApproval-custom-modal-content{
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 800px) {
  .cancelApproval-custom-modal-content{
    transform: scale(0.7);
  }
}
.headerContainer {
    width: 100%;
    display: flex;
    align-items: center;
}

.leftNav {
    display: flex;
    margin-left: 16px;
    align-items: center;
    height: 40px;
}

.LillyLogo {
    width: 58px;
    height: 32px;
}

#weave-text {
    color: #82786f;
    font-size: 24px;
    font-weight: 400;
    margin-left: 0.5rem;
}

#bar {
    color: #82786f;
    margin-left: 0.5rem;
    -webkit-transform: scaleY(2.2);
    height: 24.55px;
}


.HomeButton {
    background-color: #d52b1e;
    border: 1px solid #9e9e9e;
    color: #fff;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    margin: 0px 0px 0px 50px;
    width: 83px;
    height: 29.5px;
    border-radius: 6px;
    align-items: center;
}

.HomeButton:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #D52B1E, #D52B1E);
}

.HomeIcon {
    height: 14.23px;
    margin-right: 8px;
    width: 14px;
}

.StateDropdownbtn {
    margin-left: 8px;
    margin-top: -5px;
}

.centerNav {
    width: 50%;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-top: 16.23px;
}

.text-title {
    color: #82786f;
    font-weight: 700;
    font-size: 32px;
    line-height: 37.5px;
    height: 38px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rightnav {
    justify-content: right;
    width: 25%;
    display: flex;
    align-items: center;
    margin-right: 0px;
    margin-left: auto;
}

@media (max-width:1090px) {
    .centerNav {
        width: 45%;
    }
}

@media (max-width:1040px) {
    .centerNav {
        width: 40%;
    }
}

@media (max-width:890px) {
    .centerNav {
        width: 35%;
    }
}

@media (max-width:790px) {
    .centerNav {
        width: 20%;
    }
}

@media (max-width:640px) {
    .centerNav {
        width: 0%;
    }
}
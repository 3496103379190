.media-response-node{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 550px;
}

.media-response-node-background{
    position: relative;
    display: flex;
    justify-content: center; 
    align-items: center;
    background:#5DB8E7;
    min-width: 500px;
    width: auto;
    border-radius: 5px;
    padding:7px;
}

.media-response-logo-container{
    position: absolute;
    top: -25px; /* Half of the height */
    left: -25px; /* Half of the width */
    background: #5DB8E7;
    border-radius: 100%;
    width: 60px;
    height: 60px;
}

.media-response-logo-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.media-response-elements-area{
    display: flex;
    flex-direction: column;
    background: white;
    width: 98%; 
    height: 96%;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    border-radius: 5px;
    overflow: hidden; /* Ensure the background doesn't expand beyond its container */
    padding-bottom: 7px;
}

.media-response-elements{
    /* align-items: left; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    padding-left: 10px;
    height: auto;
    width: 100%;
    gap: 20px;
    height: 100%;
}

.media-response-element {
    width: 100%;
    border: none;
    outline: none;
    height: auto;
    overflow: scroll;
    white-space: pre-wrap;
    font-family: 'Roboto', sans-serif;
    resize: none; /* disable manual resize */
    border-radius: 5px;
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    padding: 5px;
    align-content: center;
}

.media-response-element.title::placeholder {
    color: gray;
    font-weight: lighter;
}

.media-response-element.paragraph::placeholder{
    color: gray;
    font-weight: lighter;
}

.media-response-element:hover {
    cursor: pointer;
    outline: #BDBDBD40 solid 7px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    transform: scale(1.01);
}

.media-response-element-title-container{
    margin-left: 30px;
}

.title{
    font-size: 24px;
}

.paragraph{
    font-size: 14px;
    height: 57px;
}

.media-element{
    margin-left: 5px;
    width: 100%;
    padding: 5px;
    padding-bottom: 1px;
}

.media-element-edit{
    margin-left: 20px;
    width: 100%;
    padding: 5px;
    padding-bottom: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.media-element-title-edit{
    margin-left: 0px;
    width: 100%;
    padding: 5px;
    padding-bottom: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.icon-container{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    margin-left: 5px
}


.media-response-element-container{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.media-response-element-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.media-element-image{
    align-items: center;
    background: #dcdcdc;
    border-radius: 5px;
    display: flex;
    height: 225px;
    /* height: auto; */
    justify-content: center;
    height: 225px;
    width: 93%;
    /* width: auto; */
    margin-left: 5px;
    overflow: hidden;
}

.media-element-button{
    margin-left: 5px;
    width: 100%;
    padding: 5px;
    padding-bottom: 1px;
}

.external-link-btn{
    border-radius: 4px;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
}

.add-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 50px;
}

.add-btn{
    /*Need to make the button a circle shaped*/
    display: flex;
    align-items: center;
    border-radius: 100%;
    background: #5DB8E7;
    font-weight: bold;
    cursor: pointer;
    border: none;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    transition: transform 0.4s;
    height:31px;
    width: 33px;
}

.add-btn.clicked{
    background: #9E9E9E;
}

.add-btn:hover{
    transform: scale(1.1);
}

.delete-container{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.delete-btn{
    /*Need to make the button a small squared shaped*/ 
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: #21212180;
    font-weight: bold;
    cursor: pointer;
    border: none;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    transition: transform 0.4s;
    height: 32px;
    width:32px;
    right: 34px;
    position: absolute;
}

.delete-btn:hover{
    transform: scale(1.1);
}

.media-element-title-formatting{
    display: flex;
    flex-direction: row;
    height: 32px;
    margin-top: 9px;
    width: 23%;
    align-items:center;
}

.format-options{
    background: #5db8e7;
    border: 1px solid #9E9E9EB2;
    display: flex;
    align-items: center;
    height: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
}

.bold-btn{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.link-btn{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.media-element-paragrap-formatting{
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 32px;
    margin-top: 9px;
    width: 23%;
    margin-left: 30px;
    margin-bottom: 10px;
}

.media-add-elements-container{
    display: flex;
    align-items: center;
}

.media-add-elements-area{
    align-items: center;
    display: flex;
    margin: 5px auto;
    padding-left: 20px;
}

.media-response-button-textArea{
    background: #f5f5f5;
    resize: none;
    height: auto;
    overflow: hidden;
    border-radius: 4px;
}


.btn-link-opt{
    background: #5db8e7;
    border: 1px solid #9E9E9EB2;
    display: flex;
    align-items: center;
    height: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
}

.media-response-button-textArea{
    outline: none;
}

.media-element-link-container{
    align-items: center;
    background: #5db8e7;
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-left: 30px;
    border-radius: 3px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
}

.media-element-button-formatting{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 32px;
    margin-top: 9px;
    margin-left: 30px;
    margin-bottom: 10px;
}

.media-element-link-container{
    display: flex;
    align-items: center;
    flex-direction: row;
    background: #5DB8E7;
}

.add-link-buttons{
    display: flex;
    flex-direction: column;
    margin-left: 45px;
    gap: 5px;
}

.add-link-text{
    font-family: Roboto, sans-serif;
    color: white;
    font-weight: 400;
}

.add-link-container{
    display: flex;
    flex-direction: column;
    width:68%;
}

.link-add-btns{
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 #00000026;
    color: #fff;
    display: flex;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    outline: none;
    border: none;
    justify-content: center;
}

.cancel{
    background: #DD554B;
}

.apply{
    background: #09C570;
}

.btn-add-opt{
    background: #5db8e7;
    border: 1px solid #9E9E9EB2;
    display: flex;
    align-items: center;
    height: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    margin-left:25px;
}

.media-response-add-image-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 7px;
}

.image-button-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    margin-bottom: 10px;
}

.add-image-text{
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.image-add-text-container{
    display: flex;
    align-items: center;
    justify-items:center;
    margin-left: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.image-add-btns{
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 #00000026;
    color: #fff;
    display: flex;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    outline: none;
    border: none;
    justify-content: center;
    background-color: #FF6D22;
}

/* input{
    border-radius: 5px;
    height: 30px;
    box-shadow: 0 0 10px 0 #00000026;
    outline: none;
    border: none
} */


input[type="text"] {
    border-radius: 5px;
    height: 30px;
    box-shadow: 0 0 10px 0 #00000026;
    outline: none;
    border: none
}

.add-image-button-container{
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-items: baseline;
    padding-left: 30px;
    padding-top: 35px;
}

.title-add-elements-container{
    display: flex;
    align-items: center;
    margin-left: 105px;
}

.undo{
    background: #FF6D22CC;
}

.timer-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
}

.timer{
    right: 80px;
    position: absolute;
    color: black;
    opacity: 0.5;
}
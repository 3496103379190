.SAContainer {
    background-color: #263F6A;
    border-radius: 8px;
    /* justify-items: center; */
    display: flex;
    flex-direction: column;
}

.SAContainerContent {
    max-height: 600px;
    max-width: 320px;
    min-height: 600px;
    min-width: 320px;
}

.SATopTitleSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 8%;
    border-bottom: #E0E0E0 2px solid;
    margin-bottom: 2px;
}

.SAMainTitle {
    margin-bottom: -4px;
    font-size: 20px;
    margin-left: 16px;
    color: white;
}

.SAIcon {
    font-size: 30px;
    margin-bottom: 0px;
    color: white;
}

.SASectionTitle {
    background-color: #DBDBDB;
    min-height: 8%;
    max-height: 8%;
    font-size: 18px;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 18px;
    margin-bottom: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.SAInfoSectionContainer {
    background-color: #f7b5b0;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
}

.SAInfoSection {
    width: 90%;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    padding-top: 0px;
    height: 26%;
    align-self: center;
}

.SATitle {
    font-size: 14px;
    color: #616161;
    margin-top: 4px
}

.SATitleText {
    border: black 1px solid;
    border-radius: 4px;
    height: 32px;
    /* text-align: right; */
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 10px;
    font-size: 16px;
    background: #ededed;
}

.SATitleInput {
    align-items: center;
    background: #ffffff;
    border: 1px solid #000;
    border-radius: 4px;
    display: flex;
    font-size: 16px;
    height: 36px;
    justify-content: right;
    padding-right: 10px;
    width: 100%;
    text-align: right;
}

.SAOptionSectionContainer {
    background-color: #f7b5b0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
}

.SAOptionSection {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    align-self: center;
    padding: 0 6px 4px;
    padding-left: 12px;
    width: 90%;
    padding-top: 4px;
    overflow: scroll;
    scrollbar-width: thin;
    overflow-x: hidden;
    max-height: 170px;
    min-height: 170px;
}

.SAOptionContent {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 2px !important;
}

.SAOptionTopContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
    height: fit-content;
}

.SAOptionMoveUpAndDown {
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.SAOptionMoveUp {
    background-color: white;
    border: none;
    height: fit-content;
    font-size: 18px;
    color: #263F6A;
}

.SAOptionMoveDown {
    background-color: white;
    border: none;
    height: fit-content;
    font-size: 18px;
    color: #263F6A;
    margin-top: -4px;
}

.SAOptionText {
    display: flex;
    font-size: 14px;
    margin-top: 4px;
    /* height: fit-content; */
    min-height: 50px;
    max-height: 46px;
    overflow-wrap: break-word;
    padding-left: 5px;
    resize: none;
    white-space: normal;
    width: 100%;
}

.SAOptionNewFlowContainer {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    margin-left: 4px;
}

.SAOptionDeleteButton {
    background-color: white;
    border: none;
    font-size: 16px;
}

.SAOptionNewFlowText {
    width: fit-content;
    margin-left: 2px;
    margin-right: 8px;
    font-size: 14px;
}

.SAOptionFlowNameInput {
    background: #fff;
    text-align: left;
    border: 1px solid black;
    border-radius: 4px;
    margin-left: 6px;
    width: 230px;
    height: 36px;
}

.SAOptionNewFlowCheckbox {
    width: 14px;
}

.SAAddOptionButton {
    background-color: white;
    border: none;
    align-self: center;
    width: 90%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
}

.SADeleteAndJSONContainer {
    margin-bottom: 24px;
    background-color: #f7b5b0;
    display: flex;
    justify-content: center;
    height: 10%;
}

.SADeleteAndJSON {
    width: 90%;
    display: flex;
    justify-content: space-around;
    background-color: white;
    padding-top: 4px;
    padding-bottom: 4px;
}

.SADeleteButton {
    color: white;
    background-color: #D52B1E;
    border: none;
    border-radius: 4px;
    margin-top: 6px;
    margin: 4px;
    height: 40px;
    width: 180px;
}

@media only screen and (max-width: 1600px) {
    .SAMainTitle {
        font-size: 18px;
        margin-left: 12px;
    }

    .SAIcon {
        font-size: 26px;
    }

    .SASectionTitle {
        font-size: 16px;
    }

    .SATitle {
        font-size: 12px;
    }

    .SATitleText {
        font-size: 14px;
        height: 30px;
    }

    .SATitleInput {
        font-size: 14px;
        height: 30px;
    }

    .SAOptionSection {
        max-height: 150px;
        min-height: 150px;
    }

    .SADeleteButton {
        height: 30px;
        width: 160px;
    }

    .SAContainerContent {
        max-height: 540px;
        max-width: 300px;
        min-height: 540px;
        min-width: 300px;
    }
    .SAOptionNewFlowText {
        font-size: 14px;
    }

    .SAOptionFlowNameInput {
        background: #fff;
        text-align: left;
        border: 1px solid black;
        border-radius: 4px;
        margin-left: 6px;
        width: 210px;
        height: 32px;
        font-size: 14px;
    }
    
    .SAOptionNewFlowCheckbox {
        width: 14px;
    }
}

@media only screen and (max-width: 1300px) {
    .SAMainTitle {
        font-size: 16px;
        margin-left: 10px;
    }

    .SAIcon {
        font-size: 24px;
    }

    .SASectionTitle {
        font-size: 14px;
    }

    .SATitle {
        font-size: 12px;
        margin-top: 2px;
    }

    .SATitleText {
        font-size: 12px;
        height: 26px;
    }

    .SATitleInput {
        font-size: 12px;
        height: 28px;
    }

    .SAOptionSection {
        max-height: 130px;
        min-height: 130px;
    }

    .SAOptionText {
        font-size: 12px;
        max-height: 42px;
        min-height: 44px;
    }

    .SADeleteButton {
        height: 28px;
        width: 160px;
        font-size: 15px;
    }

    .SAContainerContent {
        max-height: 480px;
        max-width: 280px;
        min-height: 480px;
        min-width: 280px;
    }
    .SAAddOptionButton {
        font-size: 15px;
    }
    .SAOptionMoveUp {
        font-size: 16px;
    }
    .SAOptionMoveDown {
        font-size: 16px;
    }
    .SAOptionDeleteButton {
        font-size: 14px;
    }
    .SAOptionNewFlowText {
        font-size: 13px;
    }

    .SAOptionFlowNameInput {
        background: #fff;
        text-align: left;
        border: 1px solid black;
        border-radius: 4px;
        margin-left: 6px;
        width: 200px;
        height: 30px;
        font-size: 13px;
    }
    
    .SAOptionNewFlowCheckbox {
        width: 12px;
    }
}
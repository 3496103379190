/* ListPage.css */

#mainContainer {
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-template-rows: 1fr;
}

.sidebarContainer {
  height: 100%;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.sidebarCreateButton{
  width: 192px;
  height: 40px;
  margin: 0 24px 24px 24px;
  color: white;
  background-color: #8553F1;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  border: none;
  border-radius: 5px;
  box-shadow: #212121;
}

.sidebarOptions {
  width: 240px;
  height: 56px;
  padding: 0 24px;
  border: none;
  background-color: transparent;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.sidebarOptions > * {
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.sidebarOptionsActive {
  position: relative;
  width: 240px;
  height: 56px;
  padding: 0 24px;
  border: none;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background-color: #EEEEEE;
}

.sidebarOptionsActive > * {
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.sidebarOptionsActive::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  width: 3px;
  height: 56px;
  background-color: #8553F1;
}

/* Center the content */
div.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Style the table header */
  div.container .rdt_TableHead {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  /* Style the table rows */
  div.container .rdt_TableRow {
    border-bottom: 1px solid #ccc;
  }
  
  /* Style the pagination */
  div.container .rdt_Pagination {
    margin-top: 10px;
  }
  
  /* Style the filter input */
  div.container .rdt_TableHeader input[type="text"] {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Style the "Go to" link */
  div.container .rdt_TableCell a {
    color: #007bff;
    text-decoration: none;
  }
  
  /* Style the loading text */
  div.container .loading {
    font-size: 18px;
    font-weight: bold;
  }
  
  /* Style the header */
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

.convo_bkg {
  position: absolute;
  top: 0;
  left: -240px;
  z-index: 999;
  width: calc(100vw + 240px);
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
}
  
.createConversationPopUp {
  width: 384px;
  height: 652px;
  position: absolute;
  top: 100px;
  left: 45%;
  z-index: 1000;
  background-color: #f1f1f1;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(64px, auto);
  padding-top: 20px;
  border-radius: 4px;
};

.titleSection {
  height: 24px;
  width: fit-content;
}

#titleSection {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 48px;
  margin-right: 14px;
}

#titleSection div {
  display: flex;
  align-items: center;
}

.plusIcon {
  color: #BDBDBD;
}

#titlePlusIcon {
  height: 30px;
  width: 30px;
  margin-right: 8px;
  color: #8553F1;
}

.titleSection div p {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  height: 24px;
  width: fit-content;
  margin: 0;
  color: #8553F1;
}

#cancelButtonIcon {
  height: 24px;
  width: 24px;
  color: #212121;
}

#cancelButton {
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  margin-right: 5px;
  align-self: flex-start;
}

.fields {
  height: 64px;
  width: 288px;
  margin: 0 48px;
  position: relative;
}

.fields label {
  height: 24px;
  width: 124px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
}

.fields input {
  height: 40px;
  width: 288px;
}

.fields select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.chevron {
  position: absolute;
  right: 5px;
  top: 50%;
  font-size: 1.5rem;
}

#docTypeContainer {
  grid-column: 1;
  grid-row: 2;
}

#tacticContainer {
  grid-column: 1;
  grid-row: 3;
}

#classContainer {
  grid-column: 1;
  grid-row: 4;
}

#groupContainer {
  grid-column: 1;
  grid-row: 5;
}

#categoryContainer {
  grid-column: 1;
  grid-row: 6;
}

#titleContainer {
  grid-column: 1;
  grid-row: 7;
}

#vaultIDContainer {
  grid-column: 1;
  grid-row: 8;
}

#createButton {
  background-color: #8553F1;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  height: 40px;
  width: 192px;
  margin-left: 48px;
  margin-right: 48px;
  margin-top: auto;
  grid-column: 1;
  grid-row: 9;
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
}

#createButtonPlusIcon {
  height: 13.33px;
  width: 13.33px;
  color: #FFFFFF;
  margin: 0 8px;
}

.bottomBar {
  height: 20px;
  width: 384px;
  background-color: #BDBDBD;
  position: relative;
  grid-column: 1;
  grid-row: 10;
  border-radius: 4px;
}

.bottomBarPurpleSection {
  height: 20px;
  width: 336px;
  border-radius: 0 0 4px 4px;
  background-color: #8553F1;
  position: absolute;
  right: 0;
}

.createConversationPopUp > :last-child {
  margin-top: auto;
}

.tableContainer {
  padding: 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.data_table_open_btn {
  opacity: 0;
  width: 62px;
  height: 32px;
  background-color: #8553F1;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  border: 1px solid #9E9E9E;
}

.rdt_TableRow:hover .data_table_open_btn {
  opacity: 1;
}
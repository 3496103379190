.TContainer {
    align-items: center;
    background-color: #fff;
    border: 2px solid #dbdbdb;
    display: flex;
    flex-direction: column;
    margin-left: -30px;
    position: relative;
    top: 4rem;
    width: 80px;
    border-radius: 4px;
    height: fit-content;
}

.THeaderContainer {
    display: flex;
    flex-direction: column;
    background-color: #263F6A;
    height: fit-content;
    align-items: center;
    border-radius: 4px 4px 0px 0px;
    width: 100%;
}

.TSettingsIcon {
    width: 22%;
    padding-top: 8px;
    padding-bottom: 8px;
}

.TTitleText {
    width: 100%;
    text-align: center;
    display: flex;
    font-weight: 700;
    justify-content: center;
    position: relative;
    border-bottom: 2px solid #dbdbdb;
    margin-bottom: 4px;
    margin-top: -4px;
    color: white;
}

.TSubtitleText {
    background: #dbdbdb;
    text-align: center;
    width: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 16px;
}

.TIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TSuggestedActionsIcon {
    width: 70%;
    margin: 6px;
    margin-top: 12px;
}

.TTextResponseIcon {
    width: 70%;
    margin: 6px;
    margin-top: 12px;
}

.TMediaCardIcon {
    width: 70%;
    margin: 6px;
}

.TExitIcon {
    width: 70%;
    margin: 6px;
}

.TFooterContainer {
    background-color: #263F6A;
    width: 100%;
    border-radius: 0px 0px 4px 4px;
}

.TFooter {
    align-items: center;
    height: 8px;
    justify-content: center;
    width: 100%;
}

.TContainer, .TExitIcon, .TMediaCardIcon, .TTextResponseIcon, .TSuggestedActionsIcon, .TFooter, .TTitleText, .TSubtitleText {
    transition: all 0.3s ease-in-out;
}

@media (max-height: 750px) {
    .TContainer {
        width: 70px;
    }
}

@media (max-height: 700px) {
    .TContainer {
        width: 60px;
    }

    .TTitleText {
        font-size: 14px;
    }

    .TSubtitleText {
        font-size: 14px;
    }

    .TSuggestedActionsIcon {
        margin: 4px;
        margin-top: 8px;
    }
    
    .TTextResponseIcon {
        margin: 4px;
        margin-top: 8px;
    }
    
    .TMediaCardIcon {
        margin: 4px;
    }
    
    .TExitIcon {
        margin: 4px;
    }

    .TFooter {
        height: 4px;
    }
}
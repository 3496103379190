.TRContainer {
    background-color: #263F6A;
    border-radius: 8px;
    /* justify-items: center; */
    display: flex;
    flex-direction: column;
}

.TRContainerContent {
    max-height: 570px;
    max-width: 320px;
    min-height: 570px;
    min-width: 320px;
}

.TRTopTitleSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 8%;
    border-bottom: #E0E0E0 2px solid;
    margin-bottom: 2px;
}

.TRMainTitle {
    margin-bottom: -4px;
    font-size: 20px;
    margin-left: 16px;
    color: white;
}

.TRIcon {
    font-size: 30px;
    margin-bottom: 0px;
    color: white;
}

.TRSectionTitle {
    background-color: #DBDBDB;
    min-height: 8%;
    max-height: 8%;
    font-size: 18px;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 18px;
    margin-bottom: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.TRInfoSectionContainer {
    background-color: #f7b5b0;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
}

.TRInfoSection {
    width: 90%;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    padding-top: 0px;
    height: 26%;
    align-self: center;
}

.TRTitle {
    font-size: 14px;
    color: #616161;
    margin-top: 4px;
}

.TRTitleText {
    border: black 1px solid;
    border-radius: 4px;
    height: 32px;
    /* text-align: right; */
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 10px;
    font-size: 16px;
    background: #ededed;
}

.TRTitleInput {
    align-items: center;
    background: #ffffff;
    border: 1px solid #000;
    border-radius: 4px;
    display: flex;
    font-size: 16px;
    height: 32px;
    justify-content: right;
    padding-right: 10px;
    width: 100%;
    text-align: right;
}

.TROptionSectionContainer {
    background-color: #f7b5b0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
}

.TROptionSection {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    align-self: center;
    padding: 0px 8px 8px 8px;
    width: 90%;
    max-height: 170px;
    min-height: 170px;
}

.TROptionContent {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 2px !important;
}

.TROptionTopContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
    height: fit-content;
}

.TROptionMoveUpAndDown {
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.TROptionMoveUp {
    background-color: white;
    border: none;
    height: fit-content;
    font-size: 18px;
    color: #263F6A;
}

.TROptionMoveDown {
    background-color: white;
    border: none;
    height: fit-content;
    font-size: 18px;
    color: #263F6A;
    margin-top: -4px;
}

.TROptionText {
    display: flex;
    font-size: 14px;
    margin-top: 4px;
    /* height: fit-content; */
    max-height: 130px;
    min-height: 130px;
    overflow-wrap: break-word;
    padding-left: 5px;
    resize: none;
    white-space: normal;
    width: 100%;
}

.TROptionNewFlowContainer {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    margin-left: 4px;
}

.TROptionDeleteButton {
    background-color: white;
    border: none;
    font-size: 16px;
}

.TROptionNewFlowText {
    width: fit-content;
    margin-left: 2px;
    margin-right: 8px;
    font-size: 14px;
}

.TROptionFlowNameInput {
    background: #fff;
    text-align: left;
    border: 1px solid black;
    border-radius: 4px;
    margin-left: 6px;
    width: 230px;
    height: 36px;
}

.TROptionNewFlowCheckbox {
    width: 14px;
}

.TRAddOptionButton {
    background-color: white;
    border: none;
}

.TRDeleteAndJSONContainer {
    margin-bottom: 24px;
    background-color: #f7b5b0;
    display: flex;
    justify-content: center;
    height: 10%;
}

.TRDeleteAndJSON {
    width: 90%;
    display: flex;
    justify-content: space-around;
    background-color: white;
    padding-top: 4px;
    padding-bottom: 4px;
}

.TRDeleteButton {
    color: white;
    background-color: #D52B1E;
    border: none;
    border-radius: 4px;
    margin-top: 6px;
    margin: 4px;
    height: 40px;
    width: 180px;
}

.Feedback_Container {
    display: flex;
    align-self: center;
}

.Feedback_Container>input {
    margin-right: 10px;
}

.Feedback_Container>p {
    font-size: 16px;
    margin: 0 !important;
}

@media only screen and (max-width: 1600px) {
    .TRMainTitle {
        font-size: 18px;
        margin-left: 12px;
    }

    .TRIcon {
        font-size: 26px;
    }

    .TRSectionTitle {
        font-size: 16px;
    }

    .TRTitle {
        font-size: 12px;
    }

    .TRTitleText {
        font-size: 14px;
        height: 30px;
    }

    .TRTitleInput {
        font-size: 14px;
        height: 30px;
    }

    .TROptionSection {
        max-height: 150px;
        min-height: 150px;
    }

    .TROptionText {
        font-size: 12px;
        max-height: 110px;
        min-height: 110px;
    }

    .TRDeleteButton {
        height: 30px;
        width: 160px;
    }

    .TRContainerContent {
        max-height: 500px;
        max-width: 300px;
        min-height: 500px;
        min-width: 300px;
    }

    .TROptionNewFlowText {
        font-size: 14px;
    }

    .TROptionFlowNameInput {
        background: #fff;
        text-align: left;
        border: 1px solid black;
        border-radius: 4px;
        margin-left: 6px;
        width: 210px;
        height: 32px;
        font-size: 14px;
    }

    .TROptionNewFlowCheckbox {
        width: 14px;
    }

    .Feedback_Container>p {
        font-size: 14px;
        margin: 0 !important;
    }
}

@media only screen and (max-width: 1300px) {
    .TRMainTitle {
        font-size: 16px;
        margin-left: 10px;
    }

    .TRIcon {
        font-size: 24px;
    }

    .TRSectionTitle {
        font-size: 14px;
    }

    .TRTitle {
        font-size: 12px;
        margin-top: 2px;
    }

    .TRTitleText {
        font-size: 12px;
        height: 26px;
    }

    .TRTitleInput {
        font-size: 12px;
        height: 28px;
    }

    .TROptionSection {
        max-height: 130px;
        min-height: 130px;
    }

    .TROptionText {
        font-size: 12px;
        max-height: 96px;
        min-height: 96px;
    }

    .TRDeleteButton {
        height: 28px;
        width: 160px;
        font-size: 15px;
    }

    .TRContainerContent {
        max-height: 450px;
        max-width: 280px;
        min-height: 450px;
        min-width: 280px;
    }

    .TRAddOptionButton {
        font-size: 15px;
    }

    .TROptionMoveUp {
        font-size: 16px;
    }

    .TROptionMoveDown {
        font-size: 16px;
    }

    .TROptionDeleteButton {
        font-size: 14px;
    }

    .TROptionNewFlowText {
        font-size: 13px;
    }

    .TROptionFlowNameInput {
        background: #fff;
        text-align: left;
        border: 1px solid black;
        border-radius: 4px;
        margin-left: 6px;
        width: 200px;
        height: 30px;
        font-size: 13px;
    }

    .TROptionNewFlowCheckbox {
        width: 12px;
    }

    .Feedback_Container>p {
        font-size: 13px;
        margin: 0 !important;
    }
}
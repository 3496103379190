#dropdown_toggle{
    /* position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    background-color: white;
    border: none; */
    background-color: #fff;
    border: none;
    position: relative;
}

#account_img{
    height: 2.5rem;   
}

/*items that are for the beta version, kept them greyed out because these will not be used in the beta release*/
.items-beta{
    opacity: 0.5;
    cursor: not-allowed;
}

.items-beta:hover{
    background-color: #fff;
}
.cancelReview-custom-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 10000;
  overflow-y: auto;
}

.cancelReview-custom-modal.show {
  display: flex;
}

.cancelReview-custom-modal-content {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 680px;
  height: fit-content;
  max-height: 80vh;
}

.cancelReview-custom-modal-header {
  display: block;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  text-align: center;
  font-size: 32px;
}


.cancelReview-close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #777;
}

.cancelReview-close-button:hover {
  color: #333;
}

.cancelReview-custom-modal-body {
  padding: 20px;
  font-size: 16px;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cancelReview-btnDiv {
  text-align: right;
}

/* Add this CSS to style the select element as a dropdown */
select {
  appearance: auto;
  /* Remove default styles */
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
}

/* Style for the options in the dropdown */
select option {
  background-color: white;
  color: #333;
}

.cancelReview-actionBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-top: 16px;
  align-items: center;
}

.cancelReview-button {
  width: 257px;
  height: 40px;
  border-radius: 5px;
}

.cancelReview-button.return {
  color: white;
  border: 2px solid #D52B1E;
  background-color: #D52B1E;
}

.cancelReview-button.return:hover {
  background-color: white;
  color: #D52B1E;
  transition: 0.15s ease;
}

.cancelReview-button.cancel {
  color: white;
  border: 2px solid #00A1DE;
  background-color: #00A1DE;
}

.cancelReview-button.cancel:hover {
  background-color: white;
  color: #00A1DE;
  transition: 0.15s ease;
}

.cancelReview-button.returnbtn:hover {
  background-color: #D52B1E;
  color: white;
  transition: 0.15s ease;
}

.cancelReview-button.delete:hover {
  background-color: #D52B1E;
  color: white;
  transition: 0.15s ease;
}

.cancelReview-button.returnbtn {
  color: #D52B1E;
  background-color: white;
}

.cancelReview-button.delete {
  color: #D52B1E;
  background-color: white;
}

.cancelReview-button.add {
  margin-left: 50px;
  background-color: #00A1DE;
  color: white;
}

.cancelReview-optionsDropdown {
  position: absolute; /* Change from relative to absolute */
  z-index: 999; /* Ensure the dropdown is above other elements */
}


.cancelReview-optionsDropdown .dropdown-btn {
  padding: 10px 130px 10px 10px;
  /* Add padding to the right */
  background: #fff;
  border: 1px solid;
  font-weight: lighter;
  color: grey;
  position: relative;
  /* Required for absolute positioning of the caret */
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  overflow-x: auto;
}


.cancelReview-optionsDropdown .dropdown-btn>svg {
  position: absolute;
  top: 50%;
  right: 10px;
  /* Adjust this value to control the distance of the caret from the text */
  transform: translateY(-50%);
  transition: transform 0.2s ease;
  /* Optional: Add a smooth transition */
  fill: #333;
}

.cancelReview-optionsDropdown .dropdown-content {
  max-height: 90px; /* Set a maximum height for the dropdown content */
  overflow-y: auto; /* Add a scrollbar when content exceeds the max height */
  top: calc(100% + 10px); /* Position below the dropdown button */
  left: 0; /* Position flush with the left edge of the button */
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  color: #333;
  cursor: pointer;
  z-index: 1000; /* Increase the z-index to cover buttons */
}

.cancelReview-optionsDropdown .dropdown-content .dropdown-item:hover {
  background: rgb(203, 199, 199);
  transition: 0.15s ease;
}

.cancelReview-optionsDropdown .dropdown-btn.active {
  transform: rotate(360deg); /* Rotate the caret 180 degrees when active */
}

.cancelReview-dropdown-content:active .dropdown-item:active{
  background:  rgb(203, 199, 199);
}

.cancelReview-selected-options-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: flex-start; /* Align selected options to the top */
  max-width: 650px;
}

.cancelReview-selected-option {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

.cancelReview-remove-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: #777;
  margin-left: 5px;
}

select option {
  color: #333;
}

.cancelReview-optionsDropdown .dropdown-content .dropdown-item.selected {
  background-color: #f0f0f0;
}

.cancelReview-button.dimmed {
  background-color: #00A1DE80; 
  border: #00A1DE80;
  color: #ffffff; 
  cursor: not-allowed; 
}

@media only screen and (max-width: 1000px) {
  .cancelReview-custom-modal-content{
    transform: scale(0.9);
  }
}

@media only screen and (max-width: 900px) {
  .cancelReview-custom-modal-content{
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 800px) {
  .cancelReview-custom-modal-content{
    transform: scale(0.7);
  }
   .cancelReview-button.cancel{
    margin-left: 10px;
  }
}
















.start-tag {
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #263F6A;
    min-width: 340px; 
    padding: 10px; 
    padding-top: 1px;
    border-radius: 10px; */
    background-color: #263f6a;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-width: 340px;
    padding: 1px 10px 10px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

}

.starRating {
    display: inline-block;
    margin-top: 10px; 
    padding-bottom: 10px;
}

.starRating svg {
    vertical-align: middle;
}

.convo-name {
    display: block;
}

.convo-text {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: white;
    margin-top: 5px; 
}

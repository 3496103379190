.requestForm-pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f4f4f9;
    color: #333;
    font-family: 'Segoe UI', Arial, sans-serif;
}

.requestForm-heading {
    color: #2a2a72;
    font-family: 'Segoe UI', Arial, sans-serif;
}

.requestForm-accessButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
    outline: none;
}

.requestForm-accessButton:hover {
    background-color: #FF9352;
}

.requestForm-accessButton:active {
    background-color: #FF9352;
}

#imgContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;

}

#textContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  translate: 0 -150%;
  margin-bottom: 1%;
}

#buttonContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  translate: 0 -150%;
}

#lillyLogo{
  height: 90%;
}

#title{
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
}
.edit-convo-popup {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 10000;
    overflow-y: auto;
  }

  .edit-convo-popup-content {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    width: 680px;
    height: fit-content;
    max-height: 80vh;
  }
  
  .edit-convo-popup-header {
    display: block;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    text-align: center;
    font-size: 32px;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #777;
  }
  
  .close-button:hover {
    color: #333;
  }
  
  .edit-convo-popup-body {
    padding: 20px;
    font-size: 16px;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .btnDiv {
    text-align: right;
  }
  
  /* Add this CSS to style the select element as a dropdown */
  select {
    appearance: auto;
    /* Remove default styles */
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
  }
  
  /* Style for the options in the dropdown */
  select option {
    background-color: white;
    color: #333;
  }
  
  .button {
    width: 257px;
    height: 40px;
    border: 2px;
    border: 2px solid #00A1DE;
    border-radius: 5px;
  }
  
  .button.return {
    margin-right: 50px;
    color: #00A1DE;
    background-color: white;
  }
  
  .button.returnbtn:hover {
    background-color: #D52B1E;
    color: white;
    transition: 0.15s ease;
  }
  
  .button.delete:hover {
    background-color: #D52B1E;
    color: white;
    transition: 0.15s ease;
  }
  
  .button.returnbtn {
    color: #D52B1E;
    background-color: white;
    border: 2px solid #D52B1E;
  }
  
  .button.delete {
    color: #D52B1E;
    background-color: white;
    border: 2px solid #D52B1E;
  }
  
  .button.return:hover {
    background-color: #00A1DE;
    color: white;
    transition: 0.15s ease;
  }
  
  .button.add {
    margin-left: 50px;
    background-color: #00A1DE;
    color: white;
  }
  
  .optionsDropdown {
    position: absolute; /* Change from relative to absolute */
    z-index: 999; /* Ensure the dropdown is above other elements */
  }
  
  
  .optionsDropdown .dropdown-btn {
    padding: 10px 130px 10px 10px;
    /* Add padding to the right */
    background: #fff;
    border: 1px solid;
    font-weight: lighter;
    color: grey;
    position: relative;
    /* Required for absolute positioning of the caret */
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    overflow-x: auto;
  }
  
  
  .optionsDropdown .dropdown-btn>svg {
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust this value to control the distance of the caret from the text */
    transform: translateY(-50%);
    transition: transform 0.2s ease;
    /* Optional: Add a smooth transition */
    fill: #333;
  }
  
  .optionsDropdown .dropdown-content {
    max-height: 90px; /* Set a maximum height for the dropdown content */
    overflow-y: auto; /* Add a scrollbar when content exceeds the max height */
    top: calc(100% + 10px); /* Position below the dropdown button */
    left: 0; /* Position flush with the left edge of the button */
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-weight: 500;
    color: #333;
    cursor: pointer;
    z-index: 1000; /* Increase the z-index to cover buttons */
  }
  
  .optionsDropdown .dropdown-content .dropdown-item:hover {
    background: rgb(203, 199, 199);
    transition: 0.15s ease;
  }
  
  .optionsDropdown .dropdown-btn.active {
    transform: rotate(360deg); /* Rotate the caret 180 degrees when active */
  }
  
  .dropdown-content:active .dropdown-item:active{
    background:  rgb(203, 199, 199);
  }
  
  .selected-options-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    align-items: flex-start; /* Align selected options to the top */
    max-width: 650px;
  }
  
  .selected-option {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  
  .remove-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: #777;
    margin-left: 5px;
  }
  
  select option {
    color: #333;
  }
  
  .optionsDropdown .dropdown-content .dropdown-item.selected {
    background-color: #f0f0f0;
  }
  
  .button.dimmed {
    background-color: #00A1DE80; 
    border: #00A1DE80;
    color: #ffffff; 
    cursor: not-allowed; 
  }

  .dltEleBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin-top: 16px;
    align-items: center;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
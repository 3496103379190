  .CMenuContainer{
    width: 349px;
    height: 40px;
  }

  .button_group {
    border-radius: 4px;
    position: absolute;
    width: 236px;
    height: 40px;
    top: -1px;
    padding-left: 7px;
  }

  .BtnGroupImg {
    width: 24px;
    height: 24px;
  }
  .BtnGroupImg:hover{
    color: white !important;
  }

  .Iconbtn{
    background-color:#f5f5f5 !important;
    width: 48px;
    height: 40px;
    border: 1px solid #9E9E9E !important;
    box-shadow: 0px 2px 3px 0px #00000033;
   }

  .Editbtn {
    border: 1px;
    border-color: #9E9E9E;
    border-radius: 4px;
    color: #FFFFFF;
    height: 40px;
    position: relative;
    width: 97px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
    background-color: #FF9352;
  }

  #UndoBtn:hover{
    background-color: #8a8a8a !important;
  }
  
  .Savebtn {
    border: 1px;
    border-color: #9E9E9E;
    border-radius: 4px;
    color: #FFFFFF;
    height: 40px;
    position: relative;
    width: 97px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
    background-color: #09C570;
  }
   .Editbtn:hover{
   background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
   linear-gradient(0deg, #FF9352, #FF9352);
   }

   .Savebtn:hover{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #09C570, #09C570);

   }

   .IoMdSavebtn{
    margin-right: 5px;
   }

   .BiSolidEditbtn{
    margin-right: 5px;
   }
   
   .react-flow__panel {
    z-index: 1000 !important;
   }
.ApproveConversation-container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow-y: auto;
  }
  
  .ApproveConversation-container-Content {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    max-width: 640px;
    align-items: center;
  }
  
  .ApproveConversation-container-Header {
    display: block;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .ApproveConversation-container-Body {
    padding: 20px;
    color: #000000;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .ApproveConversation-Button {
    width: 250px;
    height: 40px;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .ApproveConversation-Button.Return {
    background-color: #D52B1E;
    color: white;
    border: 2px solid #D52B1E;
    align-self: center;
  }
  
  .ApproveConversation-Button.Return:hover {
    color: #D52B1E;
    background-color: white;
    transition: 0.15s ease;
  }

  .ApproveConversation-Button.Approve {
    color: white;
    background-color: #00A1DE;
    border: 2px solid #00A1DE;
  }

  .ApproveConversation-Button.Approve:hover {
    background-color: white;
    color: #00A1DE;
    transition: 0.15s ease;
  }

  .ApproveConversation-HeaderText {
    text-align: center;
    font-size: 28px;
    margin-bottom: 0px;
  }

  .ApproveConversation-BodyText {
    text-align: center;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .ApproveConversation-Input{
    margin-bottom: 10px;
    min-width: 550px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    text-align: left;
    padding: 5px;
  }

  .ApproveConversation-Title {
    font-size: 14px;
    text-align: left;
    margin-bottom: 6px;
    width: 100%;
  }

  .ApproveConversation-buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .ApproveConversation-Closebutton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #777;
  }
  
  .ApproveConversation-Closebutton:hover {
    color: #333;
  }
  
  .ApproveConversation-CloseButtonContainer {
    text-align: right;
  }

  @media only screen and (max-width: 1200px) {
    .ApproveConversation-container-Content{ 
      transform: scale(0.9);
    }
  }

  @media only screen and (max-width: 1000px) {
    .ApproveConversation-container-Content{
      transform: scale(0.8);
    }
  }

  @media only screen and (max-width: 800px) {
    .ApproveConversation-container-Content{
      transform: scale(0.7);
    }
  }
  
.UpdateVaultID-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow-y: auto;
}

.UpdateVaultID-container-content {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-width: 640px;
  align-items: center;
}

.UpdateVaultID-container-header {
  display: block;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.UpdateVaultID-container-body {
  padding: 20px;
  color: #000000;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.UpdateVaultID-button {
  width: 250px;
  height: 40px;
  border: 2px solid #00A1DE;
  border-radius: 5px;
  margin-top: 10px;
}

.UpdateVaultID-button.Return {
  color: white;
  background-color: #D52B1E;
  border: 2px solid #D52B1E;
}

.UpdateVaultID-button.Return:hover {
  background-color: white;
  color: #D52B1E;
  transition: 0.15s ease;
}

.UpdateVaultID-button.Add {
  color: white;
  background-color: #00A1DE;
  border: 2px solid #00A1DE;
}

.UpdateVaultID-button.Add:hover {
  background-color: white;
  color: #00A1DE;
  transition: 0.15s ease;
}

.UpdateVaultID-container-header-text {
  text-align: center;
  font-size: 28px;
  margin-bottom: 0px;
}

.UpdateVaultID-container-body-text {
  text-align: center;
  font-size: 16px;
  padding-left: 30px;
  padding-right: 30px;
}

.UpdateVaultID-input {
  margin-bottom: 10px;
  min-width: 550px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  text-align: left;
  padding: 5px;
  resize: none;
  height: 40px;
}

.UpdateVaultID-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.UpdateVaultID-close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #777;
}

.UpdateVaultID-close-button:hover {
  color: #333;
}

.UpdateVaultID-close-container {
  text-align: right;
}

@media only screen and (max-width: 1000px) {
  .UpdateVaultID-container-content{
    transform: scale(0.9);
  }
}

@media only screen and (max-width: 850px) {
  .UpdateVaultID-container-content{
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 750px) {
  .UpdateVaultID-container-content{ 
    transform: scale(0.7);
  }

}
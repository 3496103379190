.start-tag {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #263F6A;
    min-width: 340px; 
    padding: 10px; 
    padding-top: 1px;
    border-radius: 10px;
}

.play-icon {
    display: block;
    margin-top: 10px; 
}

.play-icon svg {
    vertical-align: middle;
}

.convo-name {
    display: block;
}

.convo-text {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: white;
    margin-top: 5px; 
}

.CIContainer {
    background-color: #263F6A;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    right: -10px;
}

.CIContainerContent {
    max-height: 600px;
    max-width: 320px;
    min-height: 600px;
    min-width: 320px;
}
 
.CITopTitleSection {
    align-items: center;
    border-bottom: #E0E0E0 2px solid;
    display: flex;
    flex-direction: row;
    height: 8%;
    justify-content: center;
    margin-bottom: 2px;
    padding-top: 6px;
    padding-bottom: 6px;
}
 
.CIConInfoTitle {
    color: white;
    font-size: 20px;
    margin-bottom: -4px;
    margin-left: 16px;
}
 
.CIGlobeIcon {
    font-size: 20px;
    margin-bottom: 0px;
    color: white;
}
 
.CISectionTitle {
    background-color: #DBDBDB;
    min-height: 8%;
    max-height: 8%;    
    font-size: 18px;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 18px;
    margin-bottom: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
}
 
.CIInfoSectionContainer {
    background-color: #f7b5b0;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
}
 
.CIInfoSection {
    width: 90%;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    padding-top: 0px;
    height: 342px;    
    align-self: center;
}

.CIVersionSection {
    width: 90%;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    padding-top: 0px;
    height: 60px;    
    align-self: center;
}
 
.CITitle {
    font-size: 14px;
    color: #616161;
    margin-top: 1px;
}

.CISelectTitle {
    font-size: 14px;
    color: #616161;
    margin-top: 1px;
    position: relative;
    display: inline-block;

}
.CISelectTitle select{
    appearance: none;
}
 
.CITitleText {
    border: black 1px solid;
    border-radius: 4px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 16px;
    background: #ededed;
}

.ConversationIdText {
    border: black 1px solid;
    border-radius: 4px;
    height: 44px;
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 16px;
    background: #ededed;
    text-align: right;
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
}

.CITitleInput {
    align-items: center;
    background: #ffffff;
    border: 1px solid #9e9e9e;
    border-radius: 4px;
    display: flex;
    font-size: 16px;
    height: 32px;
    justify-content: right;
    width: 100%;
    text-align: left;
    padding: 0px 16px 0px 16px;
}
 
.Chevron{
    position: relative;
    top: -28px;    
    left: 246px;    
    width: 12px;
    height: 12px;
    pointer-events: none;
}

.CIOptionSectionContainer {
    margin-bottom: 0;
}
 
.CIDeleteContainer {
    display: flex;
    justify-content: center;
}
 
.CIDelete {
    width: 90%;
    display: flex;
    justify-content: space-around;
    background-color: white;
    padding-top: 4px;
    padding-bottom: 4px;
}
 
.CIDeleteButton {
    color: white;
    background-color: #D52B1E;
    border: none;
    border-radius: 4px;
    height: 36px;    
    width: 200px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
 
.CIFooter{
    width: 320px;
    height: 0px;
    border: 1px solid #e0e0e0;
}

@media only screen and (max-width: 1600px) {
    .CIConInfoTitle {
        font-size: 18px;
        margin-left: 12px;
    }

    .CIGlobeIcon {
        font-size: 18px;
    }

    .CISectionTitle {
        font-size: 16px;
    }

    .CITitle {
        font-size: 12px;
    }

    .ConversationIdText {
        font-size: 14px;
        height: 38px;
    }

    .CITitleText {
        font-size: 14px;
        height: 30px;
    }

    .CISelectTitle {
        font-size: 14px;
    }

    .CITitleInput {
        font-size: 14px;
        height: 30px;
    }

    .Chevron {
        top: -25px;    
        left: 226px;  
    }

    .CIInfoSection {
        height: 312px;
    }

    .CIVersionSection {
        height: 56px;    
    }

    .CIContainerContent {
        max-height: 540px;
        max-width: 300px;
        min-height: 540px;
        min-width: 300px;
    }

    .CIDelete {
        padding-bottom: 2px;
        padding-top: 2px;
    }

    .CIDeleteButton {
        height: 32px;
        width: 190px;
    }
}

@media only screen and (max-width: 1300px) {
    .CIConInfoTitle {
        font-size: 16px;
        margin-left: 10px;
    }

    .CIGlobeIcon {
        font-size: 16px;
    }

    .CISectionTitle {
        font-size: 14px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .CITitle {
        font-size: 12px;
        margin-top: 0px;
    }

    .ConversationIdText {
        font-size: 12px;
        height: 36px;
    }

    .CITitleText {
        font-size: 12px;
        height: 28px;
    }

    .CISelectTitle {
        font-size: 12px;
    }

    .CITitleInput {
        font-size: 12px;
        height: 28px;
    }

    .Chevron {
        top: -24px;    
        left: 210px !important;  
    }

    .CIInfoSection {
        height: 290px;    
    }

    .CIVersionSection {
        height: 50px;    
    }

    .CIContainerContent {
        max-height: 486px;
        max-width: 280px;
        min-height: 486px;
        min-width: 280px;
    }

    .CIDelete {
        padding-bottom: 2px;
        padding-top: 2px;
    }

    .CIDeleteButton {
        height: 28px;
        width: 180px;
        font-size: 15px;
    }
}
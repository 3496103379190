.BackToDraft-modal-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 10000;
  overflow-y: auto;
}

.BackToDraft-modal-content {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 680px;
  height: fit-content;
  max-height: 80vh;
}

.BackToDraft-close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #777;
}

.BackToDraft-modal-header {
  display: block;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  text-align: center;
  font-size: 32px;
}

.BackToDraft-modal-body {
  padding: 20px;
  font-size: 16px;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.BackToDraft-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-top: 16px;
  align-items: center;
}

.BackToDraft-button {
  width: 257px;
  height: 40px;
  border: 2px;
  border-radius: 5px;
}

.BackToDraft-button.return {
  background-color: #D52B1E;
  color: white;
  border: 2px solid #D52B1E;
}

.BackToDraft-button.return:hover {
  color: #D52B1E;
  background-color: white;
  transition: 0.15s ease;
}

.BackToDraft-button.back-to-draft {
  background-color: #00A1DE;
  color: white;
  border: 2px solid #00A1DE;
}

.BackToDraft-button.back-to-draft:hover {
  color: #00A1DE;
  background-color: white;
  transition: 0.15s ease;
}

.BackToDraft-close-button-container {
  text-align: right;
}

@media only screen and (max-width: 1200px) {
  .BackToDraft-modal-content{
    transform: scale(0.9);
  }
}

@media only screen and (max-width: 1000px) {
  .BackToDraft-modal-content{ 
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 800px) {
  .BackToDraft-modal-content{
    transform: scale(0.7);
  }
  .BackToDraft-button.back-to-draft{
    margin-left: 10px;
  }
}

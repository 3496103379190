.exitNodeText {
    font-size: 14px;
    color: #FFFFFF;
    text-wrap: wrap;
    margin-top: 4px;
    margin-bottom: 8px;
    padding-bottom: 8px;
}

.exitNodeIcon {
    font-size: 34px;
    margin-top: 0px;
    color: white;
    margin-bottom: -4px;
}
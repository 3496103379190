.CRPopUpContainer {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow-y: auto;
    /* Add this to enable scrolling if the content exceeds the viewport height */
  }
  
  /* CSS for the modal content */
  .CRPopUpContainer.show {
    display: flex;
    /* Show the modal when "show" prop is true */
  }
  
  .CRPopUpContainer-Content {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    /* Set a maximum height for the pop-up content */
    align-items: center;
  }
  
  .CRPopUpContainer-Header {
    display: block;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
  }
  
  
  .CRPopUpButton-Close {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #777;
  }
  
  .CRPopUpButton-Close:hover {
    color: #333;
  }
  
  .CRPopUpContainer-Body {
    padding: 20px;
    color: #000000;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .CRPopUpButton {
    text-align: right;
  }
  
  .CRBottomButtons {
    text-align: center;
    margin: 40px;
  }
  
  .CRButton {
    width: 250px;
    height: 40px;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .CRButton.CRButtonReturn {
    background-color: #D52B1E;
    border: 2px solid #D52B1E;
    color: white;
    align-self: center;
  }
  
  .CRButton.CRButtonReturn:hover {
    background-color: white;
    color: #D52B1E;
    transition: 0.15s ease;
  }

  .CRButton.CRButtonContinue {
    color: white;
    background-color: #00A1DE;
    border: 2px solid #00A1DE;
  }

  .CRButton.CRButtonContinue:hover {
    background-color: white;
    color: #00A1DE;
    transition: 0.15s ease;
  }

  .CRButton.CRButtonReady {
    color: white;
    background-color: #0098cf;
    border: 2px solid #0098cf;
  }

  .CRButton.CRButtonReady:hover {
    background-color: white;
    color: #0098cf;
    transition: 0.15s ease;
  }

  /**/

  .CRButton.CRButtonBack {
    color: #595959;
    background-color: #ffffff;
    border: 2px solid #ffffff;
    width: 125px;
    margin-top: 0px;
  }

  .CRButton.CRButtonBack:hover {
    background-color: #595959;
    color: #ffffff;
    transition: 0.15s ease;
    border: 2px solid #595959;
  }

  .CRButton.CRButtonConfirm {
    color: white;
    background-color: #595959;
    border: 2px solid #595959;
    width: 125px;
    margin-top: 0px;
  }

  .CRButton.CRButtonConfirm:hover {
    background-color: white;
    color: #595959;
    transition: 0.15s ease;
    border: 2px solid white;
  }

  /**/
  
  .button.add {
    margin-left: 50px;
    background-color: #00A1DE;
    color: white;
  }
  
  .optionsDropdown {
    position: absolute; /* Change from relative to absolute */
    z-index: 999; /* Ensure the dropdown is above other elements */
  }
  
  
  .optionsDropdown .dropdown-btn {
    padding: 10px 130px 10px 10px;
    /* Add padding to the right */
    background: #fff;
    border: 1px solid;
    font-weight: lighter;
    color: grey;
    position: relative;
    /* Required for absolute positioning of the caret */
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    overflow-x: auto;
  }
  
  
  .optionsDropdown .dropdown-btn>svg {
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust this value to control the distance of the caret from the text */
    transform: translateY(-50%);
    transition: transform 0.2s ease;
    /* Optional: Add a smooth transition */
    fill: #333;
  }
  
  .optionsDropdown .dropdown-content {
    max-height: 90px; /* Set a maximum height for the dropdown content */
    overflow-y: auto; /* Add a scrollbar when content exceeds the max height */
    top: calc(100% + 10px); /* Position below the dropdown button */
    left: 0; /* Position flush with the left edge of the button */
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-weight: 500;
    color: #333;
    cursor: pointer;
    z-index: 1000; /* Increase the z-index to cover buttons */
  }
  
  .optionsDropdown .dropdown-content .dropdown-item:hover {
    background: rgb(203, 199, 199);
    transition: 0.15s ease;
  }
  
  .optionsDropdown .dropdown-btn.active {
    transform: rotate(360deg); /* Rotate the caret 180 degrees when active */
  }
  
  .dropdown-content:active .dropdown-item:active{
    background:  rgb(203, 199, 199);
  }
  
  .selected-options-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    align-items: flex-start; /* Align selected options to the top */
    max-width: 650px;
  }
  
  .selected-option {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  
  .remove-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: #777;
    margin-left: 5px;
  }
  
  select option {
    color: #333;
  }
  
  .optionsDropdown .dropdown-content .dropdown-item.selected {
    background-color: #f0f0f0;
  }
  
  .button.dimmed {
    background-color: #00A1DE80; 
    border: #00A1DE80;
    color: #ffffff; 
    cursor: not-allowed; 
  }

  .CRHeaderText {
    text-align: center;
    font-size: 28px;
  }

  .CRBodyText {
    text-align: center;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .yourReviewContainer{
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 550px;
    display: flex;
    flex-direction: column;
  }

  .yourReviewTitle {
    font-size: 14px;
    text-align: left;
    margin-bottom: 6px;
    width: 100%;
  }

  .yourReviewText {
    font-size: 12px;
    text-align: left;
    margin-bottom: 6px;
    min-width: 500px;
    max-width: 500px;
  }

  .yourReviewTextArea {
    font-size: 14px;
    text-align: left;
    min-height: 80px;
    padding: 5px;
  }

  .characterCountBox {
    min-width: 50px;
    max-width: 50px;
    min-height: 20px;
    max-height: 20px;
    text-align: center;
    background-color: rgb(64, 64, 64);
    color: white;
    border-radius: 2px;
    font-size: 12px;
  }

  .reviewTextAndCharacterContainer {
    min-height: 20px;
    max-height: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .continueReadyButtonsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .backConfirmButtonsContainer {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: rgb(255, 160, 160);
    border-radius: 4px;
    margin-top: 10px;
    align-self: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .CRClosebutton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #777;
  }
  
  .CRClosebutton:hover {
    color: #333;
  }
  
  .CRCloseButtonContainer {
    text-align: right;
  }

  @media only screen and (max-width: 1200px) {
    .CRPopUpContainer-Content{
      transform: scale(0.9);
    }
  }

  @media only screen and (max-width: 1000px) {
    .CRPopUpContainer-Content{
      transform: scale(0.8);
    }
  }

  @media only screen and (max-width: 800px) {
    .CRPopUpContainer-Content{
      transform: scale(0.7);
    }
  }